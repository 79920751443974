import React from "react"

import { RegistrationTwo, Layout, SEO, Container } from "src/sws-ui"
import Authorization from "../../components/authorization"

const RegisterPage2 = () => (
  <Layout>
    <SEO title="Register" />
    <Authorization>
      <Container gridTemplateRows="1">
        <div className="cards cards--mist row-1 col-full registration--two">
          <RegistrationTwo />
        </div>
      </Container>
    </Authorization>
  </Layout>
)

export default RegisterPage2
