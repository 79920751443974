import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { moneyTransferGetProfile } from "../state/action/profile"

const Authorization = ({ children }) => {
  const dispatch = useDispatch()
  const profile = useSelector(state => state.profile.profile)
  const [ready, setReady] = useState(false)
  useEffect(() => {
    ;(async () => {
      await dispatch(
        moneyTransferGetProfile()
      )
      setReady(true)
      if (!profile.MemberId) {
        // navigate(
        //   typeof window !== "undefined" && window.location.href.includes("/th")
        //     ? "/th/login"
        //     : "/en/login"
        // )
      }
    })()
  }, [])

  if (!ready) return <></>
  return (
    <>
      {" "}
      {profile.MemberId ? (
        <>{children}</>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <h1>Unauthorized</h1>
        </div>
      )}
    </>
  )
}
export default Authorization
